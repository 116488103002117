<template>
  <div>
    <heads :backs="1" :msg="title_msg" :states="1"></heads>
    <div class="exchangeInfo">
      <div>
        <img :src="thumb" alt="">
      </div>
      <div class="exchangeName">
        <!-- <p class="name">{{ product_name }}</p> -->
        <p class="name">{{ product_name }}</p>
        <!-- <p class="name">{{ `${'product_name'.substr(0,35)}...` }}</p> -->
        <p v-show="product_type!==1&&product_type!==2" class="price">￥ {{ price }}</p>
        <p v-show="product_type==1||product_type==2" class="price"><img class="zlbimg" src="../../static/images/my_index/bingz.png" alt=""> {{ price }}</p>
      </div>
    </div>
    <div v-show="shows" class="NotLoggedIn">
      <p>请输入手机号</p>
      <div class="phone">
        <span>+86</span>
        <input v-model="phone" placeholder="请输入手机号" type="text">
      </div>
    </div>
    <div class="btn">
      <button :disabled="disabled" @click="getcode()">领取兑换码</button>
    </div>
  </div>
</template>
<script>
import heads from '@/compontens/heads.vue'
import { getredeem, productm } from '@/api/my_user.js'
import { Toast } from 'vant'
import Cookie from 'js-cookie'
export default {
  components: {
    heads
  },
  data() {
    return {
      phone: '',
      thumb: '',
      product_type: '',
      price: '',
      product_name: '',
      product_name_len: '',
      isClick: true,
      disabled: false,
      shows: false,
      title_msg: '领取兑换码'
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  },
  mounted() {
    console.log(Cookie.get('uid'))
    if (Cookie.get('uid')) {
      this.shows = false
    } else if (Cookie.get('uid') == undefined) {
      this.shows = true
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      var params = {
        id: this.$route.query.id
      }
      productm(params).then(res => {
        this.product_type = res.result.product_type
        this.product_name = res.result.product_name
        this.thumb = res.result.thumb
        this.price = res.result.price
      })
    },
    getcode() {
      var params = {
        id: this.$route.query.id,
        phone: this.phone
      }
      getredeem(params).then(res => {
        this.disabled = true
        if (this.isClick) {
          if (res.errNo == 0) {
            this.isClick = false
            Toast('领取成功')
            this.$router.push({
              path: '/ExchangeCodeReceivedSuccessfully',
              query: {
                id: res.result,
                phone: this.phone
              }
            })
          } else {
            this.disabled = false
            Toast(res.result)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.zlbimg{
  width: 28px !important;
  height: 28px !important;
  margin: 0 0 0 6px !important;
}
.exchangeName{
  margin-right: 35px;
    width: 380px;
    height: 188px;
    margin-top: 28px;
    position: relative;
    .name{
        font-size: 30px;
        width: 390px;
display: inline-block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .price{
        font-size: 30px;
        position: absolute;
        bottom: 0;
        color: #ee2e2e;

    }
}
.btn{
    width: 100%;
    margin-top: 20px;
}
button{
    width: 690px;
    height: 90px;
    background: #ee2e2e;
    color: #fff;
    display: block;
    margin: 0 auto;
}
.phone{
    width: 690px;
    height: 90px;
    background: #fff;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    align-items: center;
    span{
        width: 112px;
        display: inline-block;
        font-size: 30px;
        border-right: 1px solid #D8D8D8;
        text-align: center;
    }
    input{
        width: 200px;
        margin-left: 35px;
        color: #BABABA;
        font-size: 30px;
    }
}
.NotLoggedIn{
    width: 100%;
    height: 246px;
    p{
        color: #333;
        font-size: 32px;
        margin: 0 auto;
        width: 400px;
        text-align: center;
        margin-top: 40px;
    }
}
.exchangeInfo{
    width: 100%;
    height: 246px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    img{
        width: 260px;
        height: 188px;
        margin: 28px 0 0 36px;
    }
}
</style>
